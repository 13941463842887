import type { Tenant } from "@core/schema/Tenant.js";
import { type LogoProps, ReferencedClients, getReferences } from "./getReferences.js";

export function getLogoPanelsBasic(tenant: Tenant): LogoProps[][] {
	const references = getReferences(tenant);
	return [
		[
			references[ReferencedClients.Bito].logo,
			references[ReferencedClients.Jamujam].logo,
			references[ReferencedClients.OxPoint].logo,
			references[ReferencedClients.SladkaDilna].logo,
			references[ReferencedClients.VertiFlex].logo,
			references[ReferencedClients.Volvo].logo,
			references[ReferencedClients.FourZeroFour].logo,
			references[ReferencedClients.Brano].logo,
			references[ReferencedClients.B7].logo,
		],
		[
			references[ReferencedClients.Aukro].logo,
			references[ReferencedClients.AlPergoly].logo,
			references[ReferencedClients.Czu].logo,
			references[ReferencedClients.Diplomky].logo,
			references[ReferencedClients.HavelPartners].logo,
			references[ReferencedClients.Kvetinka].logo,
			references[ReferencedClients.KamenyNaBurni].logo,
			references[ReferencedClients.Sineko].logo,
			references[ReferencedClients.Tymbe].logo,
			references[ReferencedClients.Zms].logo,
		],
	];
}
